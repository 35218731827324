import { Button } from '@nextui-org/button'
import { BriefcaseIcon, UserCircleIcon, TrophyIcon, AcademicCapIcon } from '@heroicons/react/24/outline'
import { MerchantType } from './RegisterMain'


export function RegisterSelector({ type, onChange }: { type: MerchantType, onChange: (type: MerchantType) => void}) {
    function itemRender ( name: string, description: string, selected: boolean, icon: React.ReactNode, type:MerchantType): React.ReactNode {
        return <Button onClick={()=> (onChange(type))} className={'flex flex-row gap-3 hover:underline h-auto p-0 bg-white text-left '+ (selected ? 'text-blue-600 text-base' : '')}>
                <div className='flex flex-col text-wrap'>
                    <div className={'font-bold '+(selected ? 'text-lg': 'text-sm')}>{name}</div>
                    <div className={'font-light mt-1 text-wrap '+(selected ? 'text-sm': 'text-xs')}>{description}</div>
                </div>
            </Button>;
    }
        return (
        <div className='w-full grid grid-cols-4 items-stretch m-auto gap-10 text-black pb-5 border-b-2 border-blue-600'>
            {itemRender('Interviews', 'Interviews using AI Avatar done right',(type==='interview'), <BriefcaseIcon className={(type==='interview'?'w-8 h-8':'w-5 h-5')}/>, 'interview' )}
            {itemRender('Coaching', 'Effortless Coaching & Assessment with AI Avatar',(type==='coaching'), <AcademicCapIcon className={(type==='coaching'?'w-8 h-8':'w-5 h-5')}/>, "coaching" )}
            {itemRender('Job preparation', 'Comprehensive tools for job seekers preparation',(type==='preparation'), <UserCircleIcon className={(type==='preparation'?'w-8 h-8':'w-5 h-5')}/>, "preparation" )}
            {itemRender('Affiliate program', 'Promote JobMojito services and get commissions',(type==='affiliate'), <TrophyIcon className={(type==='affiliate'?'w-8 h-8':'w-5 h-5')}/>, "affiliate" )}
        </div>
    )
}