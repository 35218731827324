import BlockCard from '@/app/components/BlockCard'
import BlockTitle from '@/app/components/core/BlockTitle'
import { useError } from '@/app/components/providers/ErrorProvider'
import getClientSupabase from '@/app/utils/getClientSupabase'
import { ChangeEvent, useEffect, useState } from 'react'
import clientGetCookies from '@/app/components/core/clientCookies'
import { countriesAllSorted } from '@/app/utils/data/countries-all'
import {
  Button,
  Chip,
  Input,
  Divider,
  Textarea,
  Select,
  SelectItem,
} from '@nextui-org/react'
import { features } from 'process'
import {Accordion, AccordionItem} from "@nextui-org/react";
import { useRouter } from 'next/navigation'
import { MerchantType } from './RegisterMain'

export type WhiteLabelRegistration = {
  merchant_name: string

  contact_email: string | null
  contact_linkedin: string | null
  contact_person: string | null
  contact_phone: string | null

  billing_city: string | null
  billing_country: string | null
  billing_country_code: string | null
  billing_name: string | null
  billing_street: string | null
  billing_vat: string | null
  billing_zip: string | null
  billing_state: string | null

  frontend_domain: string | null
  affiliate_code: string | null

  info_business_type: string | null
  info_main_page_url: string | null
  info_notes: string | null
};

export function RegisterStep2(
  { type, completedStep, subMerchant=false, name, email }: 
  { type: MerchantType, completedStep: () => void, subMerchant?: boolean, name?:string, email?:string }) {
  const supabase = getClientSupabase()

  const initialValidationState = {
    merchant_name: true,
    billing_country: true,
    contact_person: true,
    contact_email: true,
    frontend_domain: true,
    affiliate_code: true,
    info_business_type: true
  }

  const businessType = (type==='preparation'?[
    'Coaching Agency',
    'Influencer',
    'Government Agency',
    'Job Reallocation Agency',
    'Labour Office',
    'Web Portal job listing',
    'Web Portal community',
    'Web Portal other',
    'Other',
  ]:
  ((type==='coaching') ?
  [
    'Coaching Agency',
    'University',
    'Government Agency',
    'Job Reallocation Agency',
    'Labour Office',
    'Other',
  ]
  :((type==='affiliate') ?
    [
      'Web directory',
      'Influencer',
      'Blog for Job Seekers',
      'Blog general',
      'Web Portal job listing',
      'Web Portal community',
      'Web Portal other',
      'Other',
    ]
    :
  [
    'Company 1-20 Employees',
    'Company 21-50 Employees',
    'Company 50-100 Employees',
    'Company 101-500 Employees',
    'Company 501-5000 Employees',
    'Company 5000+ Employees',
    'Integration Partner',
    'Reselling Partner',
    'Startup',
  ])));

  const router = useRouter()
  const [merchantId, setMerchantId] = useState<string | null>(null)
  const [merchant, setMerchant] = useState<WhiteLabelRegistration | null>((name && email)?{ 
    merchant_name: '', 
    contact_email:email,
    contact_linkedin: null,
    contact_person: name,
    contact_phone:  null,
  
    billing_city: null,
    billing_country: null,
    billing_country_code: null,
    billing_name: null,
    billing_street: null,
    billing_vat: null,
    billing_zip: null,
    billing_state: null,
  
    frontend_domain:  null,
    affiliate_code: null,
    info_business_type: null,
    info_main_page_url: null,
    info_notes: null
  }:null);

  const [formValidation, setFormValidation] = useState(initialValidationState)

  const [isLoading, setIsLoading] = useState(false)
  const { showError } = useError()

    // Handle form field changes
    const handleInputChange = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const { name, value } = event.target
  
      // @ts-ignore
      setMerchant({
        ...merchant,
        [name]: value,
      })
    }
  
    function savePortal() {
      setIsLoading(true)

      if (!merchant) {
        // set all fields mandatory 
        setFormValidation({
          merchant_name: false,
          billing_country: false,
          contact_person: false,
          contact_email: false,
          frontend_domain: (type=='affiliate'),
          info_business_type: false,
          affiliate_code: (type!=='affiliate'),
        });
      
        showError(
          'Please fill in all required fields before registering your business.', 'warning'
        )
        setIsLoading(false)
        return
      }

      // reset to default values
      setFormValidation(initialValidationState)
      if (
        (!merchant.merchant_name ||
          !merchant.billing_country ||
          !merchant.contact_person ||
          !merchant.contact_email ||
          !merchant.info_business_type ||
          (type!=='affiliate' && !merchant.frontend_domain) ||
          (type==='affiliate' && !merchant.affiliate_code)
          )
      ) {
        setFormValidation({
          ...formValidation,
          merchant_name: !!merchant.merchant_name,
          billing_country: !!merchant.billing_country,
          contact_person: !!merchant.contact_person,
          frontend_domain: (type=='affiliate') || !!merchant.frontend_domain,
          info_business_type: !!merchant.info_business_type,
          contact_email: !!merchant.contact_email,
          affiliate_code: (type!=='affiliate') || !!merchant.affiliate_code,
        })
        showError(
          'Please fill in all required fields before registering your business.', 'warning'
        )
  
        setIsLoading(false)
        return;
        
      }

      const affiliateid = clientGetCookies('affiliate_id');
      const isAffiliateId = ((affiliateid!==undefined) && (affiliateid!==null) && (affiliateid!==''));

      supabase.rpc('merchant_register', {
        in_type: type,
        in_data: {
          'merchant_name': (merchant.merchant_name && merchant.merchant_name!=='')? merchant.merchant_name:null,
          'contact_email': (merchant.contact_email && merchant.contact_email!=='')? merchant.contact_email.toLowerCase().trim():null,
          'contact_linkedin': (merchant.contact_linkedin && merchant.contact_linkedin!=='')? merchant.contact_linkedin:null,
          'contact_person': (merchant.contact_person && merchant.contact_person!=='')? merchant.contact_person:null,
          'contact_phone': (merchant.contact_phone && merchant.contact_phone!=='')? merchant.contact_phone:null,
          'billing_name': (merchant.billing_name && merchant.billing_name!=='')? merchant.billing_name:null,
          'billing_city': (merchant.billing_city && merchant.billing_city!=='')? merchant.billing_city:null,
          'billing_country': (merchant.billing_country && merchant.billing_country!=='')? merchant.billing_country : null,
          'billing_country_code': (merchant.billing_country_code && merchant.billing_country_code!=='')? merchant.billing_country_code:null,
          'billing_street': (merchant.billing_street && merchant.billing_street!=='')? merchant.billing_street : null,
          'billing_vat': (merchant.billing_vat && merchant.billing_vat!=='')? merchant.billing_vat:null,
          'billing_zip': (merchant.billing_zip && merchant.billing_zip!=='')? merchant.billing_zip:null,
          'billing_state': (merchant.billing_state && merchant.billing_state!=='')? merchant.billing_state : null,
          'info_business_type': (merchant.info_business_type && merchant.info_business_type!=='')? merchant.info_business_type:null,
          'info_main_page_url': (merchant.info_main_page_url && merchant.info_main_page_url!=='')? merchant.info_main_page_url:null,
          'info_notes': (merchant.info_notes && merchant.info_notes!=='')? merchant.info_notes:null,

          'affiliate_code': (merchant.affiliate_code && merchant.affiliate_code!=='')? merchant.affiliate_code: null,
          'affiliate_id':  (affiliateid && affiliateid!=='')? affiliateid:null,

          'frontend_domain': (merchant.frontend_domain===null ? null : ((type==='preparation'||type==='coaching')? merchant.frontend_domain.toLowerCase().trim()+'.jobmojito.com':(type==='interview'?merchant.frontend_domain.toLowerCase().trim()+'.interviewmojito.com':null))),
        },
        in_sub_merchant: subMerchant
      })
      .then(({ data: updatedData, error: updateError }) => {
        setIsLoading(false)
        if (updateError) {
          showError(updateError.message, 'error')
        } else {
          showError('Account has been succesfully created', 'success');
          completedStep();
        }
      })
    }
  
    return (
      <>
      <div className={'my-5 flex flex-col md:flex-row gap-5 justify-between'}>
              <div className={'md:w-1/2'}>
        <BlockTitle title={'Main Contact Person'} size='xs' />
        <BlockCard className={'mt-2 flex flex-col gap-5'}>
          <Input
            label={'Contact Person'}
            value={merchant?.contact_person || ''}
            isInvalid={!formValidation.contact_person}
            name={'contact_person'}
            isRequired
            onChange={handleInputChange}
            radius="sm"
          />
          <Input
            label={'Contact Email'}
            value={merchant?.contact_email || ''}
            isInvalid={!formValidation.contact_email}
            name={'contact_email'}
            isRequired
            onChange={handleInputChange}
            radius="sm"
          />
          {/*
          <Input
            label={'Contact Phone'}
            value={merchant?.contact_phone || ''}
            name={'contact_phone'}
            onChange={handleInputChange}
            radius="sm" />
          <Input
            label={'Linkedin URL'}
            value={merchant?.contact_linkedin || ''}
            name={'contact_linkedin'}
            onChange={handleInputChange}
            radius="sm" />*/}
          </BlockCard>
        {((type==='preparation') || (type==='coaching') || (type==='interview')) ? 
        <>
        <BlockTitle title={'Merchant domain'} size='xs' className='mt-5' />
        <BlockCard className={'mt-2 flex flex-col gap-5'}>
          <Input
            label={'Domain URL'}
            value={merchant?.frontend_domain || ''}
            isInvalid={!formValidation.frontend_domain}
            name={'frontend_domain'}
            isRequired
            onChange={handleInputChange}
            radius="sm"
            endContent={((type==='preparation'||type==='coaching')?'.jobmojito.com':'.interviewmojito.com')}
          />
          <div className='text-black'>
            Notes:
            <ul className='list-disc text-sm text-zinc-500 ml-5 '>
              <li>You can change the Web Domain to your own URL after the registration</li>
              <li>Further adjustments of the platform colors, logo and texts are available in Portal customisation after this registration</li>
            </ul>
          </div>
          </BlockCard>
          </>
          :
            <>
            <BlockTitle title={'Affiliate tracking code'} size='xs' className='mt-5' />
            <BlockCard className={'mt-2 flex flex-col gap-5'}>
              <Input
                label={'Your affiliate tracking code'}
                value={merchant?.affiliate_code || ''}
                isInvalid={!formValidation.affiliate_code}
                name={'affiliate_code'}
                isRequired
                onChange={handleInputChange}
                radius="sm"
              />
              <div className='text-black'>
                Notes:
                <ul className='list-disc text-sm text-zinc-500 ml-5 '>
                  <li>Code must be atleast 6 characters long</li>
                  <li>You can change yout tracking code after the registration</li>
                </ul>
              </div>
              </BlockCard>
              </>
            }
        </div>
      <div className={'md:w-1/2'}>
        <BlockTitle title={'Business Information'} size='xs' />
        <BlockCard className={'mt-2 flex flex-col gap-5 '}>
          <Input
            label={'Merchant Account Name'}
            isRequired
            value={merchant?.merchant_name || ''}
            name={'merchant_name'}
            onChange={handleInputChange}
            isInvalid={!formValidation.merchant_name}
            radius="sm"
          />
          <Select
              label="Business type"
              placeholder="Select type of your business"
              disallowEmptySelection={true}
              defaultSelectedKeys={
                (merchant?.info_business_type?merchant?.info_business_type:'')
              }
              isInvalid={!formValidation.info_business_type}
              selectionMode={'single'}
              onChange={(e) => {
                const { name, value } = e.target;
                // @ts-ignore
                setMerchant({
                  ...merchant,
                  ['info_business_type']: value,
                })
              }}
              isRequired
          >
              {businessType.map((type) => (
              <SelectItem key={type} value={type}>
                  {type}
              </SelectItem>
              ))}
          </Select>
          <Select
                label="Country"
                placeholder="Select your Country"
                disallowEmptySelection={true}
                defaultSelectedKeys={
                  (merchant?.billing_country?merchant?.billing_country:'')
                }
                isInvalid={!formValidation.billing_country}
                selectionMode={'single'}
                onChange={(e) => {
                  const { name, value } = e.target;
                  const element = countriesAllSorted.find((country) => country.code === value);

                  if ((element) && (element!==null) && (merchant))  {
                    setMerchant({
                      ...merchant,
                      ['billing_country']: element.name,
                      ['billing_country_code']: element.code,
                    })
                  }
                }}
                isRequired
            >
                {countriesAllSorted.map((country) => (
                <SelectItem key={country.code} value={country.code} startContent={country.emoji}>
                    {country.name}
                </SelectItem>
                ))}
            </Select>

          {/*
          <Input
            label={'Your Main Page URL'}
            value={merchant?.info_main_page_url || ''}
            name={'info_main_page_url'}
            onChange={handleInputChange}
            radius="sm"
          />*/}
          <Divider />
          <Accordion isCompact>
            <AccordionItem key="1" aria-label="Accordion 1" title="Company details">
              <div className='flex flex-col gap-3'>
            <Input
              label={'Legal Name (Company or Your Name)'}
              value={merchant?.billing_name || ''}
              name={'billing_name'}
              onChange={handleInputChange}
              radius="sm"
            />
            <Input
              label={'Street'}
              value={merchant?.billing_street || ''}
              name={'billing_street'}
              onChange={handleInputChange}
              radius="sm"
            />
            <Input
              label={'City'}
              // @ts-ignore
              value={merchant?.billing_city || ''}
              name={'billing_city'}
              onChange={handleInputChange}
              radius="sm"
            />
            <Input
              label={'Zip'}
              // @ts-ignore
              value={merchant?.billing_zip || ''}
              name={'billing_zip'}
              onChange={handleInputChange}
              radius="sm"
            />
            <Input
              label={'State / Province'}
              value={merchant?.billing_state || ''}
              name={'billing_state'}
              onChange={handleInputChange}
              radius="sm"
            />
            <Input
              label={'VAT number'}
              value={merchant?.billing_vat || ''}
              name={'billing_vat'}
              onChange={handleInputChange}
              radius="sm"
            />
            </div>
            </AccordionItem>
          </Accordion>
          {/*
          <Divider />
          <Textarea
            label={'Any other notes'}
            value={merchant?.info_notes || ''}
            name={'info_notes'}
            onChange={handleInputChange}
            radius="sm"
          />*/}
        </BlockCard>
      </div>
    </div>
    <Button color='primary'
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={()=>{ savePortal(); }}>Save & Next</Button>
    </>
)
}